import React, { useEffect, useState } from 'react';

const EventUserRegistration = () => {
  const [userCreated, setUserCreated] = React.useState(false);
  const [error, setError] = React.useState('');

  const [groupNameValue, setGroupNameValue] = useState(new URLSearchParams(window.location.search).get("event") || "");

  const handleGroupNameChange = (e: any) => {
    setGroupNameValue(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await fetch(new URL('/event-registration/create-user', import.meta.env.VITE_API_URL), {
      method: 'POST',
      body: new FormData(e.currentTarget),
    });

    if (response.ok) {
      setUserCreated(true);
    }

    const reponseText = await response.text();

    if (response.status === 409) {
      if (reponseText.includes("email")) {
        setError('Un compte existe déjà avec cette adresse email');
      } else if (reponseText.includes("username")) {
        setError("Le nom d'utilisateur n'est pas disponible");
      } else {
        setError('Une erreur est survenue');
      }
    }

    if (response.status === 400) {
      if (reponseText.includes("Invalid groupName")) {
        setError("Code d'évènement invalide");
      } else if (reponseText.includes("registration disabled")) {
        setError('La création de compte est temporairement désactivée.');
      } else if (reponseText.includes("Invalid userName")) {
        setError('Le nom d\'utilisateur est invalide.');
      } else if (reponseText.includes("Invalid email address")) {
        setError('L\'adresse email est invalide.');
      } else {
        setError('Une erreur est survenue');
      }
    }

    if (response.status === 404) {
      setError('Serveur inacessible');
    }

    if (response.status === 500) {
      setError('Une erreur est survenue');
    }
  };

  //Delete error after 10 seconds
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 10000);
    }
  }, [error]);

  return (
    <div className='flex h-dvh w-full flex-col items-center justify-center bg-black text-sm text-black'>
      <div className='h-[100px] w-[200px]'>
        <img
          src='/logo-white.svg'
          alt='Logo'
          className='h-full w-full object-contain'
        />
      </div>
      <div className='flex max-w-[350px] flex-col gap-4 rounded-2xl bg-white p-6 px-8 tracking-tighter'>
        {userCreated ? (
          <div className='w-full p-4'>
            Votre compte a été créé avec le mot de passe temporaire <b>Beink2024</b>.
            <div>
              <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="/">Me connecter</a>
            </div>
          </div>
          
        ) : (
          <>
            <div className='py-2 text-center font-bold'>Création de compte</div>
            <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
              <div className='flex flex-col gap-2'>
                <label htmlFor='username'>Nom d'utilisateur</label>
                <input
                  required
                  type='text'
                  id='userName'
                  name='userName'
                  placeholder="Nom d'utilisateur"
                  className='rounded-md border p-2'
                  pattern='^[A-Za-z][A-Za-z0-9_]{3,24}$'
                />
                <i className="text-xs">
                  Entre 3 et 24 caractères, ne peut contenir que des lettres, chiffres ou le caractère _.
                </i>
              </div>
              <div className='flex flex-col gap-2'>
                <label htmlFor='email'>Email</label>
                <input
                  required
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Email'
                  className='rounded-md border p-2'
                />
              </div>
              <div className='flex flex-col gap-2'>
                <label htmlFor='group'>Profession</label>
                <input
                  required
                  type='text'
                  id='jobTitle'
                  name='jobTitle'
                  placeholder='Profession'
                  className='rounded-md border p-2'
                />
              </div>
              <div className='flex flex-col gap-2'>
                <label htmlFor='group'>Code événement</label>
                <input
                  required
                  type='text'
                  id='groupName'
                  name='groupName'
                  value={groupNameValue}
                  onChange={handleGroupNameChange}
                  placeholder="Code événement"
                  className='rounded-md border p-2'
                />
              </div>

              <div className='flex flex-col gap-2 pt-4'>
                {error && (
                  <div
                    className='rounded-md bg-rose-500 bg-opacity-75 p-3 px-4 text-white'
                    role='alert'>
                    {error}
                  </div>
                )}

                <button
                  className='w-full rounded-2xl bg-green-to-violet-gradient p-4 text-white'
                  type='submit'>
                  Créer un compte
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default EventUserRegistration;
