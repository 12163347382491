import { useTranslation } from 'react-i18next';

import TabHelp from '../Tabs/TabHelp';

import TextArea from '../../../lib/TextArea';
import { usePanelContext } from '../context/usePanelContext';
import { usePanelInputContext } from '../context/usePanelInputContext';
import { motion } from 'framer-motion';

export const Inpaint = function Inpaint() {
  const { showHelp, setShowHelp } = usePanelContext();
  const { inpaintInputText, setInpaintInputText } = usePanelInputContext();

  const { t } = useTranslation(['panel']);

  return (
    <motion.div layout className='w-full'>
      {/* HELP */}
      {showHelp && (
        <TabHelp
          setShowHelp={setShowHelp}
          steps={[
            t('panel:Inpaint.help.step1'),
            t('panel:Inpaint.help.step2'),
            t('panel:Inpaint.help.step3'),
          ]}
        />
      )}
      <div className='flex w-full flex-col gap-3'>
        <TextArea
          label={t('panel:Inpaint.promptLabel')}
          id='prompt'
          rows={4}
          placeholder={t('panel:Inpaint.promptPlaceholder')}
          value={inpaintInputText}
          onChange={(e) => {
            setInpaintInputText(e.target.value);
          }}
        />
      </div>
    </motion.div>
  );
};
