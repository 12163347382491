import { useKeycloak } from '@react-keycloak/web';
import { Album } from '../../types/api';

import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';

const useCreateAlbum = () => {
  const { keycloak } = useKeycloak();
  const { setAlbums } = useAlbumContext();

  const createAlbumFromImages = async (images: File[]) => {
    const url = new URL('/album', import.meta.env.VITE_API_URL);

    //Create an album
    const response = await fetch(url, {
      body: JSON.stringify({
        name: 'New Album',
        description: 'This is a new album',
      } as Album),
      headers: {
        'authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const data = (await response.json()) as Album;
    console.log(data);

    //Add the images
    const albumId = data.id;

    const imageUploadUrl = new URL(
      `/album/${albumId}/image`,
      import.meta.env.VITE_API_URL
    );

    for (const image of images) {
      const formData = new FormData();
      formData.append('image', image);

      await fetch(imageUploadUrl, {
        body: formData,
        headers: {
          authorization: `Bearer ${keycloak.token}`,
        },
        method: 'POST',
      });
    }

    //Fetch the album
    const albumUrl = new URL(`/album/${albumId}`, import.meta.env.VITE_API_URL);
    const albumResponse = await fetch(albumUrl, {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    });
    const albumData = (await albumResponse.json()) as Album;

    //Push the album to the context
    setAlbums((albums) => [albumData, ...albums]);

    return albumData;
  };

  return {
    createAlbumFromImages,
  };
};

export default useCreateAlbum;
