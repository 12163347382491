import {
  convertToExcalidrawElements,
  Excalidraw,
  exportToBlob,
  MainMenu,
} from '@excalidraw/excalidraw';
import i18next from 'i18next';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  BinaryFiles,
  DataURL,
  ExcalidrawImperativeAPI,
  ExcalidrawInitialDataState,
} from '@excalidraw/excalidraw/types/types';
import {
  ExcalidrawFrameElement,
  ExcalidrawFreeDrawElement,
  ExcalidrawImageElement,
  ExcalidrawRectangleElement,
  FileId,
  NonDeletedExcalidrawElement,
} from '@excalidraw/excalidraw/types/element/types';
import { initFilesDB, getFiles, storeFiles } from '../store/excalidrawFilesDB';
import { throttle } from 'lodash';
import Panel from '../components/Creator/Panel/Panel';
import { $excalidrawElements, $excalidrawState } from '../store/excalidraw';
import { useStore } from '@nanostores/react';
import { $language } from '../store/language';
import { PanelProvider } from '../components/Creator/Panel/context/PanelContext';

import { motion } from 'framer-motion';
import { AlbumProvider } from '../components/Creator/Panel/context/AlbumContext';
import { PanelInputProvider } from '../components/Creator/Panel/context/PanelInputContext';

//Creator Context
type CreatorContextType = {
  hasObjectSelected: boolean;
  hasImageSelected: boolean;
  hasDrawingSelected: boolean;
  hasSelectedDrawingsOutsideSelectedImages: boolean;
  importImageFromDOMElement: (domElementId: string) => void;
  exportSelectedElementsAsImage: () => Promise<string>;
  exportForInpainting: () => Promise<string[]>;
};
export const CreatorContext = createContext<CreatorContextType | undefined>(
  undefined
);

export const useCreatorContext = () => {
  const context = useContext(CreatorContext);
  if (context === undefined) {
    throw new Error('useCreatorContext must be used within a CreatorContext');
  }
  return context;
};

export default function Creator() {
  const [hasObjectSelected, setHasObjectSelected] = useState<boolean>(false);
  const [hasImageSelected, setHasImageSelected] = useState<boolean>(false);
  const [hasDrawingSelected, setHasDrawingSelected] = useState<boolean>(false);
  const [
    hasSelectedDrawingsOutsideSelectedImages,
    setHasSelectedDrawingsOutsideSelectedImages,
  ] = useState<boolean>(false);

  const getLangCode = () => {
    const storedLangCode = useStore($language);
    if (storedLangCode) return storedLangCode === 'fr' ? 'fr-FR' : 'en';

    return i18next.language === 'fr' ? 'fr-FR' : 'en';
  };

  const [isDBReady, setIsDBReady] = useState<boolean>(false);
  let savedFilesIds: string[] = [];

  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI>();

  const excalidrawState = useStore($excalidrawState);
  const excalidrawElements = useStore($excalidrawElements);

  // Init files DB in order to get saved files
  initFilesDB()
    .then((status) => setIsDBReady(status))
    .catch((err) => {
      console.warn(err);
    });

  const getInitialData =
    async (): Promise<ExcalidrawInitialDataState | null> => {
      // No state saved in localStorage, no initial data
      if (!excalidrawState || !isDBReady) return null;

      const appState = JSON.parse(excalidrawState);
      appState.collaborators = []; // Prevent "Unexpected Application Error! collaborators.forEach is not a function"
      const elements = JSON.parse(excalidrawElements);

      const storedFiles = await getFiles();
      if (storedFiles) savedFilesIds = Object.keys(storedFiles);

      return {
        appState: appState,
        elements: elements || [],
        files: storedFiles || {},
      } as ExcalidrawInitialDataState;
    };

  // saveExcalidraw save Excalidraw appSate, elements and files in the indexedDB
  // of the browser
  const saveExcalidraw = async () => {
    if (!excalidrawAPI || !isDBReady) return;

    const appState = excalidrawAPI.getAppState();
    const elements = excalidrawAPI.getSceneElements();
    const allFiles = excalidrawAPI.getFiles();

    const files = new Object() as BinaryFiles;

    // Filter out files related to existing images on the canvas as Excalidraw
    // keeps files even when images are deleted (for undo/redo purpose)
    let hasNewFile = false;
    elements
      .filter((element) => element.type == 'image')
      .map((element) => {
        const image = element as ExcalidrawImageElement;
        const fileId = image.fileId;
        if (fileId) {
          files[fileId] = allFiles[fileId];
          if (!savedFilesIds.includes(fileId as string)) {
            hasNewFile = true;
          }
        }
      });

    // Store state and elements in localStorage
    $excalidrawState.set(JSON.stringify(appState));
    $excalidrawElements.set(JSON.stringify(elements));

    // Store files in indexedDB only if modified (new file or change of size)
    if (hasNewFile || savedFilesIds.length != Object.keys(files).length) {
      storeFiles(files)
        .then(() => {
          savedFilesIds = Object.keys(files);
          console.debug('files saved');
        })
        .catch((err) => {
          // XXX: maybe handle this error later by alerting the user and
          // inviting them to manually save Excalidraw
          console.warn('files save error:', err);
        });
    }
  };

  // Save Excalidraw on visibility change (usefull on touch device)
  // Note: in case of unload/close of the page, files are not saved as it
  // is an asynchronous operation (indexedDB). That's why we also save
  // everything every 1 second.
  document.addEventListener('visibilitychange', function () {
    // fires when user switches tabs, apps, goes to homescreen, etc.
    if (document.visibilityState == 'hidden') {
      saveExcalidraw();
    }
  });

  // Auto-save Excalidraw every second mainly for saving files as this
  // is an asynchronous operation (indexedDB)
  useEffect(() => {
    const interval = setInterval(() => {
      saveExcalidraw();
    }, 1000);
    return () => clearInterval(interval);
  }, [excalidrawAPI]);

  // onChange determines the multiple states needed by the beink panel.
  // Run it at most once every 1 second with lodash throttle as onChange
  // is triggered on any move on the canvas. We could use onMousDown and
  // onMouseUp instead but it doesn't take changes made with keys into account
  // (ctrl+c for instance).
  const onChange = throttle((elements: any, state: any) => {
    if (!Object.keys(state.selectedElementIds).length) {
      setHasObjectSelected(false);
      return;
    }

    setHasObjectSelected(true);

    const selectedElements = getSelectedElements(elements);
    const selectedImages = selectedElements.filter(
      (element: any) => element.type == 'image'
    );
    const selectedDrawings = selectedElements.filter(
      (element: any) => element.type == 'freedraw'
    );

    if (selectedImages.length) {
      setHasImageSelected(true);
    } else {
      setHasImageSelected(false);
      setHasSelectedDrawingsOutsideSelectedImages(false);
    }

    if (selectedDrawings.length) {
      setHasDrawingSelected(true);
    } else {
      setHasDrawingSelected(false);
      setHasSelectedDrawingsOutsideSelectedImages(false);
    }

    // Determine hasSelectedDrawingsOutsideSelectedImages
    if (selectedImages.length && selectedDrawings.length) {
      const boundingRect = buildBoundingRect(selectedImages);
      if (!boundingRect) return;

      let outside = false;
      selectedDrawings.map((drawing) => {
        if (isElementOutsideRect(drawing, boundingRect)) {
          outside = true;
        }
      });

      setHasSelectedDrawingsOutsideSelectedImages(outside);
    }
  }, 1000);

  // importImageFromDOMElement imports a generation result image on the
  // center of the canvas
  const importImageFromDOMElement = (domElementId: string) => {
    const imgElement = document.getElementById(
      domElementId
    ) as HTMLImageElement;
    if (!imgElement || !excalidrawAPI) return;

    // Convert the image to a base64 data URL
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = imgElement.naturalWidth;
    canvas.height = imgElement.naturalHeight;
    ctx.drawImage(imgElement, 0, 0);
    const dataUrl = canvas.toDataURL('image/png');

    const fileId = crypto.randomUUID() as FileId;
    const imgFile = {
      id: fileId,
      dataURL: dataUrl as DataURL,
      mimeType: 'image/png',
      created: Date.now(),
    };

    // Compute image position in order to center it
    const { width, height, scrollX, scrollY, zoom } =
      excalidrawAPI.getAppState();

    // Compute the zoom scroll values adjusted by excalidraw in order
    // to keep elements centered when zooming/dezooming
    const zoomScrollX = ((1 / zoom.value - 1) * width) / 2;
    const zoomScrollY = ((1 / zoom.value - 1) * height) / 2;

    // Compute the canvas center based on canvas dimension and scroll values
    const canvasCenterX = width / 2 - (scrollX - zoomScrollX);
    const canvasCenterY = height / 2 - (scrollY - zoomScrollY);

    // Center the image based on the canvas center
    const x = canvasCenterX - imgElement.naturalWidth / 2;
    const y = canvasCenterY - imgElement.naturalHeight / 2;

    // @ts-ignore
    excalidrawAPI.addFiles([imgFile]);

    excalidrawAPI.updateScene({
      elements: [
        ...excalidrawAPI.getSceneElements(),
        convertToExcalidrawElements([
          {
            type: 'image',
            x: x,
            y: y,
            width: imgElement.naturalWidth,
            height: imgElement.naturalHeight,
            fileId: fileId,
            scale: [1, 1],
          },
        ])[0],
      ],
      commitToHistory: true,
    });
  };

  const getSelectedElements = (
    elements: NonDeletedExcalidrawElement[]
  ): NonDeletedExcalidrawElement[] => {
    if (!excalidrawAPI) return [];

    const selectedElementIds = Object.keys(
      excalidrawAPI.getAppState().selectedElementIds
    );

    if (!selectedElementIds.length) return [];

    // No elements given: fetch from the scene
    if (!elements.length) {
      elements = [...excalidrawAPI.getSceneElements()];
    }

    // Filter only selected elements
    const selectedElements = elements.filter((element) =>
      selectedElementIds.includes(element.id)
    );

    return selectedElements;
  };

  const exportSelectedElementsAsImage = async (): Promise<string> => {
    if (!excalidrawAPI) return '';

    // Filter only selected elementss
    const selectedElements = getSelectedElements([]);

    if (!selectedElements.length) return '';

    return await imageFromElements(selectedElements, null);
  };

  const imageFromElements = async (
    elements: NonDeletedExcalidrawElement[],
    frame: ExcalidrawFrameElement | null,
  ): Promise<string> => {
    if (!excalidrawAPI) return '';

    // Export selected elements to a Blob (image)
    const blob = await exportToBlob({
      elements: elements,
      files: excalidrawAPI.getFiles(),
      exportingFrame: frame,
      exportPadding: 0,
      mimeType: 'image/png',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const dataImg = reader.result?.toString() || '';
          resolve(dataImg);
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  const buildBoundingRect = (
    elements: NonDeletedExcalidrawElement[]
  ): ExcalidrawRectangleElement | null => {
    if (!excalidrawAPI) return null;

    // Compute bounding box
    const boundingRect = elements.reduce(
      (acc, element) => {
        return {
          minX: Math.min(acc.minX, element.x),
          minY: Math.min(acc.minY, element.y),
          maxX: Math.max(acc.maxX, element.x + element.width),
          maxY: Math.max(acc.maxY, element.y + element.height),
        };
      },
      {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      }
    );

    // Calculate width and height of bounding box
    const width = boundingRect.maxX - boundingRect.minX;
    const height = boundingRect.maxY - boundingRect.minY;

    return convertToExcalidrawElements([{
        type: 'rectangle',
        angle: 0,
        x: boundingRect.minX,
        y: boundingRect.minY,
        width: width,
        height: height,
        fillStyle: 'solid',
        strokeStyle: 'solid',
        roughness: 0,
        opacity: 100,
        backgroundColor: '#000',
        strokeWidth: 1,
        strokeColor: '#000',
      }
    ])[0] as ExcalidrawRectangleElement;
  };

  // getRealDrawingPosition returns the left-top-most x, y point of the drawing because
  // native x, y attributes are the first point of drawing.
  const getRealDrawingPosition = (element: ExcalidrawFreeDrawElement) => {
    const correctedPosition = element.points.reduce(
      (acc, point) => {
        return {
          minX: Math.min(acc.minX, point[0]),
          minY: Math.min(acc.minY, point[1]),
          maxX: Math.max(acc.maxX, point[0]),
          maxY: Math.max(acc.maxY, point[1]),
        };
      },
      {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      }
    );

    return {
      x: element.x + correctedPosition.minX,
      y: element.y + correctedPosition.minY,
    };
  };

  // isElementOutsideRect returns true if the given element is at least partially outside the given rect
  const isElementOutsideRect = (
    element: NonDeletedExcalidrawElement,
    rect: ExcalidrawRectangleElement
  ): boolean => {
    // x, y attributes of the freedraw element is the first point of drawing and not the left-top-most
    // point therefore compute the "real" x, y
    const { x, y } = getRealDrawingPosition(
      element as ExcalidrawFreeDrawElement
    );

    // Determine if element position is out of bound for each side of the rectangle
    return (
      x < rect.x || // Left
      x + element.width > rect.x + rect.width || // Right
      y < rect.y || // Top
      y + element.height > rect.y + rect.height
    ); // Bottom
  };

  const exportForInpainting = async (): Promise<string[]> => {
    if (!excalidrawAPI) return [];

    const selectedElements = getSelectedElements([]);

    const selectedImages = selectedElements.filter(
      (element: any) => element.type == 'image'
    );

    if (!selectedImages.length) return [];

    const boundingRect = buildBoundingRect(selectedImages);

    if (!boundingRect) return [];

    const selectedMasks = selectedElements
      .filter(
        (element: NonDeletedExcalidrawElement) => element.type == 'freedraw'
      )
      // Select only drawings inside the bounding rectangle and remove drawing points
      // outside the bounding rectangle
      .reduce(
        (
          selectedElements: NonDeletedExcalidrawElement[],
          element: NonDeletedExcalidrawElement
        ) => {
          const freedraw = element as ExcalidrawFreeDrawElement;
          let points = [...freedraw.points];

          // First and last points different means the drawing is unclosed:
          // add the first point at the end in order to close it with a straight
          // line
          if (points[0] !== points[points.length - 1]) {
            points.push(points[0]);
          }

          selectedElements.push(
            convertToExcalidrawElements([{
                type: freedraw.type,
                x: freedraw.x,
                y: freedraw.y,
                width: freedraw.width,
                height: freedraw.height,
                backgroundColor: '#fff',
                strokeWidth: freedraw.strokeWidth,
                strokeColor: '#fff',
                points: points,
                simulatePressure: false,
                pressures: [],
              } as unknown as ExcalidrawFreeDrawElement
            ])[0]
          );

          return selectedElements;
        },
        []
      );

    if (!selectedMasks.length) return [];

    // Add a frame in order to crop freedraws exceeding the bounding rectangle
    const frame = {
      type: "frame",
      x: boundingRect.x,
      y: boundingRect.y,
      width: boundingRect.width,
      height: boundingRect.height,
      angle: 0,
      strokeColor: "#bbb",
      backgroundColor: "transparent",
      fillStyle: "solid",
      strokeWidth: 1,
      strokeStyle: "solid",
      children: [],
    } as unknown as ExcalidrawFrameElement;

    const dataImg = await imageFromElements(selectedImages, null);
    const maskImg = await imageFromElements([boundingRect, ...selectedMasks], frame);

    return [dataImg, maskImg];
  };

  return (
    <div className='relative grid h-dvh w-screen overflow-hidden md:grid-rows-1 '>
      <div className='creator h-full w-full'>
        <Excalidraw
          excalidrawAPI={(api: ExcalidrawImperativeAPI) =>
            setExcalidrawAPI(api)
          }
          langCode={getLangCode()}
          initialData={getInitialData()}
          onChange={onChange}
          viewModeEnabled={false}
          zenModeEnabled={false}
          gridModeEnabled={false}
          name='Custom name of drawing'>
          <MainMenu>
            <MainMenu.DefaultItems.LoadScene />
            <MainMenu.DefaultItems.SaveToActiveFile />
            <MainMenu.DefaultItems.Export />
            <MainMenu.DefaultItems.SaveAsImage />
            <MainMenu.Separator />
            <MainMenu.DefaultItems.ClearCanvas />
            <MainMenu.Separator />
            <MainMenu.DefaultItems.ToggleTheme />
            <MainMenu.DefaultItems.ChangeCanvasBackground />
            <MainMenu.Separator />
            <MainMenu.DefaultItems.Help />
          </MainMenu>
        </Excalidraw>
      </div>

      <motion.div
        layout
        className='pointer-events-none absolute z-[500] flex h-full w-full items-end justify-end overflow-hidden'>
        {/* TODO: extract to a separate file to prevent rerenders */}
        <CreatorContext.Provider
          value={{
            hasObjectSelected,
            hasImageSelected,
            hasDrawingSelected,
            hasSelectedDrawingsOutsideSelectedImages,
            importImageFromDOMElement,
            exportSelectedElementsAsImage,
            exportForInpainting,
          }}>
          <AlbumProvider>
            <PanelProvider>
              <PanelInputProvider>
                <Panel />
              </PanelInputProvider>
            </PanelProvider>
          </AlbumProvider>
        </CreatorContext.Provider>
      </motion.div>
    </div>
  );
}
