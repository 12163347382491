import {
  faAnglesRight,
  faHouse,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../lib/SimpleButton';
import { usePanelContext } from './context/usePanelContext';
import { BeinkPhase } from '../../../types';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import LinkButton from '../../lib/LinkButton';
//import { isMobile } from '../../../helpers/device';

const PanelHeader = () => {
  const { isPanelOpen, setIsPanelOpen, setPhase } = usePanelContext();

  return (
    <motion.div
      layout
      className={twMerge(
        `sticky top-0 pointer-events-auto flex w-full items-center justify-between py-4
        pb-6 z-[1000] `,
        !isPanelOpen && 'justify-center'
      )}>
      {/* REDUCE BUTTON */}
      <motion.div
        layout
        className='items-centers flex'
        animate={{ rotate: isPanelOpen ? 0 : 180 }}>
        <SimpleButton
          round
          theme='flat'
          variant='tertiary'
          size='squareMD'
          iconSize='16px'
          leftIcon={isPanelOpen ? faTimes : faAnglesRight}
          onClick={() => {
            setIsPanelOpen((prev) => !prev);

            setPhase(BeinkPhase.Generate);
          }}
        />
      </motion.div>

      {/* LOGO */}
      {isPanelOpen && (
        <div className='h-[48px]'>
          <img
            src='/logo-white.svg'
            alt='Logo'
            className='h-full w-full object-cover'
          />
        </div>
      )}

      {/* HOME BUTTON */}
      {isPanelOpen && (
        <LinkButton
          to='/'
          variant='tertiary'
          round
          iconSize='16px'
          size='squareMD'
          leftIcon={faHouse}
        />
      )}
    </motion.div>
  );
};

export default PanelHeader;
