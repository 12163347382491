import { useKeycloak } from '@react-keycloak/web';

import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';

const useFetchAlbums = () => {
  const { keycloak } = useKeycloak();
  const { setAlbums } = useAlbumContext();

  const url = new URL('/album', import.meta.env.VITE_API_URL);

  const fetchAlbums = async () => {
    console.log('fetching albums');
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${keycloak.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch albums');
      }
      const data = await response.json();

      setAlbums(data);
    } catch (err) {
      console.error(err);
    }
  };

  return { fetchAlbums };
};

export default useFetchAlbums;
