import { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Album } from '../../types/api';

type AlbumImage = {
  id: string;
  src: string;
};

const useGetAlbumImages = (album: Album | null) => {
  const { keycloak } = useKeycloak();
  const [images, setImages] = useState<AlbumImage[]>();
  const [loading, setLoading] = useState(true);

  const url = new URL('/album', import.meta.env.VITE_API_URL);

  //Fetch an image from the album
  const fetchImage = async (albumId: string, imageId: string) => {
    const path = `/${albumId}/image/${imageId}`;
    const response = await fetch(url + path, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });

    const blob = await response.blob();

    return { id: imageId, src: URL.createObjectURL(blob) };
  };

  //Fetch all the images concurrently with Promise.all
  const fetchImages = async () => {
    const promises = album?.images.map((image) =>
      fetchImage(album.id, image.id)
    );
    const images = await Promise.all(promises || []);
    setImages(images);
    setLoading(false); // Set loading to false after fetching images
  };

  useEffect(() => {
    if (keycloak.token) {
      fetchImages();
    }
  }, [keycloak.token, album]);

  return { images, loading };
};

export default useGetAlbumImages;
