import {
  faBookOpen,
  faPencil,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { Album } from '../../../../../types/api';
import { DropdownElementProps } from '../../../../lib/Dropdown/types/dropdown.types';
import { useAlbumContext } from '../../context/useAlbumContext';

import SimpleButton from '../../../../lib/SimpleButton';

export const AlbumDropdownElement = ({
  album,
  isSelected,
  close,
  reset,
}: DropdownElementProps & { album: Album }) => {
  const { setAlbumToModify } = useAlbumContext();

  return (
    <div className='flex w-full items-center justify-between gap-2'>
      <div className='flex items-center gap-2'>
        {isSelected ? (
          <div
            className='aspect-square rounded-md bg-white p-2'
            onClick={(e) => {
              e.stopPropagation();
              reset && reset();
            }}>
            <SimpleButton
              className='text-text-negative'
              size='squareXXS'
              leftIcon={faTimes}
            />
          </div>
        ) : (
          <div className='aspect-square rounded-md bg-white p-2'>
            <SimpleButton
              className='text-text-negative'
              size='squareXXS'
              leftIcon={faBookOpen}
            />
          </div>
        )}
        <div>{album.name}</div>
      </div>
      {isSelected && (
        <div
          className='cursor-pointer rounded-md border p-2'
          onClick={(e) => {
            e.stopPropagation();
            close && close();
            setAlbumToModify(album);
          }}>
          <SimpleButton size='squareXXS' leftIcon={faPencil} />
        </div>
      )}
    </div>
  );
};
