
import SimpleButton, { SimpleButtonProps } from './SimpleButton';

type LinkButtonProps = {
  to: string;
} & Omit<SimpleButtonProps, 'href'>;

const LinkButton = ({ to, ...rest }: LinkButtonProps) => {
  return (
    <a href={to} className='flex items-center justify-center'>
      <SimpleButton {...rest} />
    </a>
  );
};

export default LinkButton;
