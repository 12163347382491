import { useKeycloak } from '@react-keycloak/web';
import { Album } from '../../types/api';
import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';

/**
 * Custom hook to delete an image from an album and update the context accordingly.
 *
 * @param {Album} album - The album from which the image will be deleted.
 * @returns {Object} An object containing the `deleteImageFromAlbum` function.
 *
 * @function deleteImageFromAlbum
 * @async
 * @param {string} imageId - The ID of the image to be deleted.
 * @returns {Promise<Album>} A promise that resolves to the updated album.
 *
 * @example
 * const { deleteImageFromAlbum } = useDeleteImageInAlbum(album);
 *
 */
const useDeleteImageInAlbum = () => {
  const { keycloak } = useKeycloak();
  const { setAlbums, setAlbumToModify, setCurrentAlbum, currentAlbum } =
    useAlbumContext();

  const deleteImageFromAlbum = async (albumId: string, imageId: string) => {
    const imageDeleteUrl = new URL(
      `/album/${albumId}/image/${imageId}`,
      import.meta.env.VITE_API_URL
    );

    await fetch(imageDeleteUrl, {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
      method: 'DELETE',
    });

    // Fetch the updated album
    const albumUrl = new URL(`/album/${albumId}`, import.meta.env.VITE_API_URL);
    const albumResponse = await fetch(albumUrl, {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    });
    const albumData = (await albumResponse.json()) as Album;

    try {
      // Replace the album in the context
      setAlbums((albums) =>
        albums.map((a) => (a.id === albumData.id ? albumData : a))
      );
      // Update the current album to modify so the UI updates
      setAlbumToModify(albumData);
      // If the current album is the one being modified, update it
      if (currentAlbum?.id === albumData.id) {
        setCurrentAlbum(albumData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    deleteImageFromAlbum,
  };
};

export default useDeleteImageInAlbum;
