import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../../../lib/SimpleButton';
import { usePanelContext } from '../../context/usePanelContext';
import { DropdownHeaderProps } from '../../../../lib/Dropdown/types/dropdown.types';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const AlbumDropdownHeader = <T,>({ close }: DropdownHeaderProps<T>) => {
  const { t } = useTranslation(['panel']);
  const { setShowCreateNewAlbum } = usePanelContext();
  return (
    <motion.div layout className=''>
      <motion.div
        layout
        className='flex w-full items-center justify-between px-4'>
        <motion.div layout className='font-semibold'>
          {t('panel:Inspire.AlbumDropdownHeader')}{' '}
        </motion.div>
        <SimpleButton
          leftIcon={faPlus}
          className={'bg-green-to-violet-gradient text-white'}
          size='squareXS'
          onClick={() => {
            close && close();
            setShowCreateNewAlbum(true);
          }}
          variant='primary'></SimpleButton>
      </motion.div>
    </motion.div>
  );
};
