import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Nav from "../components/Nav";

const BeinkLayout = () => (
  <main className="grid h-dvh grid-rows-[min-content,_1fr] overflow-hidden">
    <Header />
    <div className="flex w-full h-full overflow-hidden">

    <Nav />
    <Outlet />
    </div>
  </main>
);

export default BeinkLayout;
