import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../components/svg/Logo';
import { ExternalLink, ChevronLeft } from 'lucide-react';

const Header = memo(() => {
  const { t } = useTranslation(["translation"]);
  const location = useLocation();

  return (
    <header className=' z-100 flex w-full items-center justify-between gap-4 bg-grey-400 px-6 py-3'>
      <div>
        <a href='/'>
          <Logo className='h-[60px] fill-primary' />
        </a>
      </div>

      {location.pathname === '/' ? (
        <a
          href='/creator'
          className='gradient-btn flex items-center gap-1 space-x-2.5 rounded-full px-6 py-2 font-bold'>
          <span>{t('Home.LaunchApp')}</span>
          <ExternalLink width={20} />
        </a>
      ) : (
        <a
          href='/'
          className='flex items-center gap-2 px-6 py-2 hover:text-primary'>
          <ChevronLeft width={20} />
          <span>{t('Home.Nav.BackHome')}</span>
        </a>
      )}
    </header>
  );
});

export default Header;
