import { useState } from 'react';
import { Album } from '../../../../types/api';
import useUpdateAlbum from '../../../../hooks/albums/useUpdateAlbum';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useAlbumContext } from '../context/useAlbumContext';

type RenamableAlbumProps = {
  album: Album;
};

const RenamableAlbum = ({ album }: RenamableAlbumProps) => {
  const [newName, setNewName] = useState(album.name);
  const [isEditing, setIsEditing] = useState(false);

  const { updateAlbumName } = useUpdateAlbum();
  const { albumToModify, setAlbumToModify } = useAlbumContext();

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
    console.log(newName);
  };

  const editName = async () => {
    if (!album) return;
    if (newName === '') {
      setNewName(album.name);
      setIsEditing(false);
      return;
    }
    try {
      console.log(album.id);
      await updateAlbumName(album, album.id, newName);
      setIsEditing(false);

      if (albumToModify) setAlbumToModify({ ...album, name: newName });
    } catch (error) {
      console.error('Failed to update album name', error);
    }
  };

  const keyDownName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      //Check if the name is empty
      if (newName === '') {
        setNewName(album.name);
        setIsEditing(false);
        return;
      }
      editName();
    }
  };

  return (
    <div
      className='flex justify-between rounded-lg bg-white p-2 px-4 text-sm text-text-negative'
      onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type='text'
          value={newName}
          onChange={changeName}
          onKeyDown={keyDownName}
          onBlur={editName}
          autoFocus
          className='w-full border-b border-gray-300 focus:outline-none'
        />
      ) : (
        <div> {album.name}</div>
      )}
      <div className='cursor-pointer' onClick={() => setIsEditing(true)}>
        <FontAwesomeIcon icon={faPencil} />
      </div>
    </div>
  );
};

export default RenamableAlbum;
