import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import GenerationsSection from "../components/Generations";
import { useTranslation } from "react-i18next";
import { Coins, Copy } from "lucide-react";

const HomePage = () => {
  const { keycloak } = useKeycloak();
  const [userName, setUserName] = useState("");
  const { t } = useTranslation(["translation"]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(keycloak.token || "");
    toast(t("Home.CopyToast"));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getUser = async () => (await keycloak.loadUserInfo()) as any;
    getUser().then((userInfo) => {
      setUserName(userInfo.given_name)
    });
  }, [keycloak]);

  return (
    <div className="p-8 overflow-y-auto">
      <h1 className="font-extrabold text-2xl md:text-5xl mb-8">
        {t("Home.Welcome")} {userName},
      </h1>

      <div className="flex gap-3 mb-3 items-center">
        <Coins className="text-primary" />
        <p className="font-bold">{t("Home.Token")}</p>
      </div>

      <button
        className="py-4 px-5 bg-grey rounded-md gap-3 inline-flex items-center mb-8 w-full lg:w-2/4 group"
        onClick={copyToClipboard}
      >
        <input
          value={keycloak.token}
          disabled
          className="bg-transparent flex-1 pointer-events-none"
        />
        <Copy width={20} className="group-hover:text-primary" />
      </button>

      <GenerationsSection />
    </div>
  );
};

export default HomePage;
