import { useKeycloak } from '@react-keycloak/web';
import { Album } from '../../types/api';

import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';

/**
 * Custom hook to add images to an album and updtate the context accordingly.
 *
 * @param {Album} album - The album to which images will be added.
 * @returns {Object} An object containing the `createAlbumFromImages` function.
 *
 * @function createAlbumFromImages
 * @async
 * @param {File[]} images - An array of image files to be added to the album.
 * @returns {Promise<Album>} A promise that resolves to the updated album.
 *
 * @example
 * const { createAlbumFromImages } = useAddImageToAlbum(album);
 *
 */
const useAddImageToAlbum = (album: Album) => {
  const { keycloak } = useKeycloak();
  const { setAlbums, setAlbumToModify, currentAlbum, setCurrentAlbum } =
    useAlbumContext();

  const addImageToAlbum = async (images: File[]) => {
    //Check if the album has space left from environment variable
    if (
      album.images.length + images.length >
      Number(import.meta.env.VITE_MAX_IMAGES_PER_ALBUM)
    ) {
      throw new Error('Album is full');
    }

    const imageUploadUrl = new URL(
      `/album/${album.id}/image`,
      import.meta.env.VITE_API_URL
    );

    for (const image of images) {
      const formData = new FormData();
      formData.append('image', image);

      await fetch(imageUploadUrl, {
        body: formData,
        headers: {
          authorization: `Bearer ${keycloak.token}`,
        },
        method: 'POST',
      });
    }

    //Fetch the album
    const albumUrl = new URL(
      `/album/${album.id}`,
      import.meta.env.VITE_API_URL
    );
    const albumResponse = await fetch(albumUrl, {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    });
    const albumData = (await albumResponse.json()) as Album;

    //TODO: add that to other hooks so they can be used outside the panel context ?
    try {
      //Replace the album in the context
      setAlbums((albums) =>
        albums.map((a) => (a.id === albumData.id ? albumData : a))
      );
      //Update the current album to modify so the UI updates
      setAlbumToModify(albumData);
      //TODO: this logic might not be ideal
      //if the album is the current album, update the current album
      if (currentAlbum?.id === albumData.id) {
        setCurrentAlbum(albumData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    addImageToAlbum,
  };
};

export default useAddImageToAlbum;
