import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { $generationsLeft } from '../store/generations-left';
import timeStampParam from './api';
import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { $email, $firstName, $lastName } from '../store/user';
import { $generations } from '../store/generations';

export const ProtectedLayout = () => {
  const location = useLocation();

  const currentLocationState = location.state || { from: { pathname: '/' } };

  const { initialized, keycloak } = useKeycloak();
  const { t } = useTranslation(['panel']);

  const [isError, setIsError] = useState(false);

  const fetchGenerations = async () => {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/generation${timeStampParam}&page=0&order=desc&sort=date`,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    );
    const data = await response.json();
    if (data.error) {
      setIsError(true);
      return;
    }
  
    $generations.set(
      data.generations.map(
        (generation: {
          createdAt: string;
          generatedImagesUrl: string[];
          input: string;
          graphicStyle: string;
        }) => {
          return {
            createdAt: generation.createdAt,
            urls: generation.generatedImagesUrl,
            input: generation.input,
            graphicStyle: generation.graphicStyle,
          };
        }
      )
    );
  };

  useEffect(() => {
    if (!keycloak?.authenticated) return;

    fetch(`${import.meta.env.VITE_API_URL}/user-info${timeStampParam}`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setIsError(true);
        }
        $generationsLeft.set(data.generationsLeft);

        $firstName.set(data.user.firstName);
        $lastName.set(data.user.lastName);
        $email.set(data.user.email);
      })
      .catch(() => setIsError(true));

    fetchGenerations();
  }, [initialized, keycloak]);

  if (isError) {
    console.error('An error has occurred while retrieving user infos.');
  }

  if (!initialized) {
    return (
      <div className='flex h-dvh w-dvw flex-col items-center justify-center bg-grey'>
        <div className='mb-4 w-full'>
          <Loader2 className='mx-auto animate-spin text-primary' size={40} />
        </div>
        <p className='text-center text-xl'>{t('panel:Loading.title')}</p>
      </div>
    );
  }

  if (!keycloak?.authenticated) {
    keycloak?.login();
    return <Navigate to={currentLocationState.from} />;
  }

  return <Outlet />;
};
