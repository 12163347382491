//Get today's date
const today = new Date();
//Check if date is after the 31th of October
const isAfterEndDate = today >= new Date(2024, 10, 1);

export const stripeEnabled: boolean =
  import.meta.env.VITE_FEATURE_STRIPE === 'true' || false;
export const inpaintEnabled: boolean =
  import.meta.env.VITE_FEATURE_INPAINT === 'true' || false;
export const teamManagementEnabled: boolean =
  import.meta.env.VITE_FEATURE_TEAM === 'true' || false;
export const fnacEnabled: boolean =
  import.meta.env.VITE_FEATURE_FNAC_EVENT_STARTED === 'true' || false;
export const fnacStopped: boolean = isAfterEndDate;
export const userRegistrationEnabled: boolean =
  import.meta.env.VITE_USER_REGISTRATION_ENABLED === 'true' || false;