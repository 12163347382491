import { useMediaQuery } from '@mui/material';
import MobilePanel from './mobile/MobilePanel';
import useGenerate from '../../../hooks/generation/useGenerate';
import useFetchAlbums from '../../../hooks/albums/useGetAlbums';
import PanelContent from './PanelContent';
import { useEffect } from 'react';
import { usePanelContext } from './context/usePanelContext';

function Panel() {
  const isMobile = useMediaQuery('(max-width: 729px)');
  
  const { inProgressGeneration, setInProgressGeneration } = usePanelContext();
  const { getInProgressGeneration, getGeneration } = useGenerate();

  // Get current in progress generation if any
  useEffect(() => {
    getInProgressGeneration().then((generation) => {
      if (generation)
        setInProgressGeneration(generation);
    });
  }, []);

  // Get in-progress generation progress
  useEffect(() => {
    if (!inProgressGeneration)
      return;

    const interval = setInterval(() => {
      getGeneration(inProgressGeneration.id);
    }, 1000);
    return () => clearInterval(interval);
  }, [inProgressGeneration]);

  const { fetchAlbums } = useFetchAlbums();
  useEffect(() => {
    fetchAlbums();
  }, []);

  return isMobile ? <MobilePanel /> : <PanelContent />;
}

export default Panel;
