import { useAlbumContext } from '../../context/useAlbumContext';
import Dropdown from '../../../../lib/Dropdown/Dropdown';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { AlbumDropdownHeader } from './AlbumDropdownHeader';
import { AlbumDropdownElement } from './AlbumDropdownElement';
import { Album } from '../../../../../types/api';
import { Option } from '../../../../lib/Dropdown/types/dropdown.types';
import { useTranslation } from 'react-i18next';

const AlbumDropdown = () => {
  const { currentAlbum, setCurrentAlbum, albums } = useAlbumContext();
  const { t } = useTranslation(['panel']);

  const triggerStyles = currentAlbum
    ? 'bg-d-surface-bg-accent text-text-negative'
    : 'outline outline-1 outline-d-text-2 bg-transparent';

  const initialAlbum: Option<Album> | null = currentAlbum && {
    id: currentAlbum.id,
    label: currentAlbum.name,
    value: currentAlbum,
    element: (props) => (
      <AlbumDropdownElement {...props} album={currentAlbum} />
    ),
  };

  const albumOptions: Option<Album>[] = albums.map((album) => ({
    id: album.id,
    label: album.name,
    value: album,
    element: (props) => <AlbumDropdownElement {...props} album={album} />,
  }));

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={initialAlbum}
        options={albumOptions}
        Header={(props) => <AlbumDropdownHeader {...props} />}
        search
        isContentContained={false}
        icon={faBookOpen}
        showSelectedWithinList={true}
        placeholder={t('panel:Inspire.AlbumPlaceholder')}
        triggerStyles={triggerStyles}
        onChange={(option) => {
          setCurrentAlbum(option ? option.value : null);
        }}
      />
    </div>
  );
};

export default AlbumDropdown;
