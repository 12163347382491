import { Dispatch, SetStateAction, useState, createContext } from 'react';
import { Album } from '../../../../types/api';

type AlbumContextType = {
  currentAlbum: Album | null;
  setCurrentAlbum: Dispatch<SetStateAction<Album | null>>;
  albums: Album[];
  setAlbums: Dispatch<SetStateAction<Album[]>>;
  albumToModify: Album | null;
  setAlbumToModify: Dispatch<SetStateAction<Album | null>>;
};

export const AlbumContext = createContext<AlbumContextType | undefined>(
  undefined
);

export const AlbumProvider = ({ children }: { children: React.ReactNode }) => {
  const [albums, setAlbums] = useState<Album[]>([]);
  const [currentAlbum, setCurrentAlbum] = useState<Album | null>(null);
  const [albumToModify, setAlbumToModify] = useState<Album | null>(null);

  return (
    <AlbumContext.Provider
      value={{
        albumToModify,
        setAlbumToModify,
        albums,
        setAlbums,
        currentAlbum,
        setCurrentAlbum,
      }}>
      {children}
    </AlbumContext.Provider>
  );
};
