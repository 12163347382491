import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { $generationsLeft } from '../../store/generations-left';
import { GenerationStatus, type DesignStyle, type Proposals } from '../../types';
import useAppContext from '../useAppContext';
import { dataURItoBlob } from '../../helpers/ImageManipulation';
import { intlDateTimeShortFormater } from '../../helpers/dateManipulation';

import { $generations } from '../../store/generations';

export type GenerateImage = ({
  graphicStyle,
  input,
  dataImg,
}: {
  graphicStyle: DesignStyle;
  input: string;
  dataImg: string;
}) => void | Promise<void>;

type ProposalWithGenIdDTO = {
  genId: string;
  date: string;
  images: string[];
};

const useGenerate = () => {
  const { t } = useTranslation(["panel"]);
  const { generationsLeft } = useAppContext();
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [proposals, setProposals] = useState<Proposals>([]);
  const [proposalsWithGenId, setProposalsWithGenId] =
    useState<ProposalWithGenIdDTO>();

  const generateImage = ({
    graphicStyle,
    input,
    dataImg,
  }: {
    graphicStyle: DesignStyle;
    input: string;
    dataImg: string;
  }) => {
    if (!dataImg.startsWith('data:image/png;base64,'))
      throw new Error('Invalid dataImg');

    if (!keycloak.token) {
      return setError(t('panel:Error.wrongKey'));
    }

    if (generationsLeft < 1) {
      return setError(t('panel:Error.noAttemptLeft'));
    }

    setIsLoading(true);

    setError('');

    const formData = new FormData();
    formData.append('input', input);
    formData.append('graphicStyle', graphicStyle);
    formData.append('source', 'Webapp');
    formData.append('scribble', dataURItoBlob(dataImg), 'scribble.png');

    const url = new URL('/ai/imagine-sync', import.meta.env.VITE_API_URL);

    return fetch(url, {
      body: formData,
      method: 'POST',
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status === GenerationStatus.COMPLETED) {
          const proposals = data.urls ?? data.upscaled_urls ?? [];
          setProposals(proposals);
          setProposalsWithGenId({
            genId: data.id,
            date: intlDateTimeShortFormater.format(new Date(data.createdAt)),
            images: proposals,
          });
          $generationsLeft.set(data.generationsLeft);

          $generations.set([
            {
              id: data.id,
              createdAt: data.createdAt,
              urls: data.urls,
              input: data.prompt,
              graphicStyle: data.graphicStyle,
              status: data.status,
            },
            ...$generations.get(),
          ]);
        } else {
          setError(t('panel:Error.default'));
          setProposals([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(t('panel:Error.default'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    error,
    proposals,
    proposalsWithGenId,
    generateImage,
  };
};

export default useGenerate;
