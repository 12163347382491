import { useState } from 'react';
import useGetAlbumImages from '../../../../hooks/albums/useGetAlbumImages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Album } from '../../../../types/api';
import DeletableAlbumImage from './DeletableAlbumImage';
import useAddImageToAlbum from '../../../../hooks/albums/useAddImageToAlbum';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ImageDropzone from '../../../lib/Inputs/ImageDropzone';
import { addImagesDialog } from '../../../../helpers/fileHelpers';
import { Loader2 } from 'lucide-react';
import ImageLoading from '../Results/ImageLoading';

interface AlbumImageGalleryProps {
  album: Album;
}

const AlbumImageGallery = ({ album }: AlbumImageGalleryProps) => {
  const { t } = useTranslation(['panel']);
  const { images, loading } = useGetAlbumImages(album);
  const { addImageToAlbum } = useAddImageToAlbum(album);
  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const [isEveryFileUnder10MB, setIsEveryFileUnder10MB] = useState(true);

  const isAlbumFull = album.images.length >= 5;

  const updateAlbum = async (files: File[]) => {
    setIsUploadingImages(true);
    try {
      await addImageToAlbum(files);
      setIsUploadingImages(false);
    } catch (e) {
      console.log('Error updating album', e);
      setIsUploadingImages(false);
    }
  };
  
  const handleDrop = async (files: File[]) => {
    if (isAlbumFull) return;
    updateAlbum(files);
  };

  const handleAddFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    addImagesDialog(async (files) => {
      if (files.length === 0) {
        setIsEveryFileUnder10MB(false);
        return;
      }
      setIsEveryFileUnder10MB(true);

      updateAlbum(files);
    });
  };

  return (
    <motion.div className='relative' layout='position'>
      <ImageDropzone onDrop={handleDrop} padding={[1, 1]} showCTAonDrag={true}>
        {/* UPLOADING IMAGE LOADER */}
        {isUploadingImages ? (
          <div className='flex flex-col items-center gap-4'>
            <Loader2 className='animate-spin text-white' size={30} />
            <div className=''>
              {t('panel:Inspire.NewAlbum.UploadingImages')}
            </div>
          </div>
        ) : (
          <div className='grid h-min w-full grid-cols-3 gap-x-6 gap-y-4'>
            {loading
              ? /* IMAGE PLACEHOLDERS */
                Array(album.images.length)
                  .fill(undefined)
                  .map((_, i) => (
                    <div
                      key={i}
                      className='aspect-square overflow-hidden rounded-xl'>
                      <ImageLoading />
                    </div>
                  ))
              : /* IMAGES */
                images?.map((image) => (
                  <DeletableAlbumImage
                    key={image.id}
                    src={image.src}
                    albumId={album.id}
                    imageId={image.id}
                  />
                ))}

            {!isAlbumFull && (
              <>
                {/* ADD IMAGE Button */}
                <button
                  onClick={handleAddFiles}
                  className='flex aspect-square cursor-pointer items-center justify-center rounded-xl border border-dashed border-white hover:opacity-50'>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='text-2xl text-white'
                  />
                </button>
                {/* DROP CTA */}
                <div className='col-span-3 my-4 text-center text-sm'>
                  {t('panel:Inspire.NewAlbum.DropCTA')}
                </div>
              </>
            )}
          </div>
        )}


        {!isEveryFileUnder10MB && (
          <div className='text-center text-sm'>
            {t('panel:Inspire.NewAlbum.bigFiles')}
          </div>
        )}
      </ImageDropzone>
    </motion.div>
  );
};

export default AlbumImageGallery;
