import { useKeycloak } from '@react-keycloak/web';
import { Album } from '../../types/api';

import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';

const useUpdateAlbum = () => {
  const { keycloak } = useKeycloak();
  const { setAlbums } = useAlbumContext();

  const updateAlbumName = async (
    album: Album,
    albumId: string,
    newName: string
  ) => {
    const url = new URL(`/album/${albumId}`, import.meta.env.VITE_API_URL);
    console.log(newName);

    await fetch(url, {
      body: JSON.stringify({
        ...album,
        name: newName,
      } as Album),
      headers: {
        'authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    });

    //Replace the album name in the context
    setAlbums((albums) => {
      return albums.map((a) => {
        if (a.id === album.id) {
          return { ...a, name: newName };
        }
        return a;
      });
    });
  };

  return {
    updateAlbumName,
  };
};

export default useUpdateAlbum;
