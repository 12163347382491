import ResultsGenerationImage from './ResultsGenerationImage';
import { useStore } from '@nanostores/react';
import { $generations } from '../../../../store/generations';
import { usePanelContext } from '../context/usePanelContext';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import SimpleButton from '../../../lib/SimpleButton';
import { BeinkPhase } from '../../../../types';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';

const LastGeneration = () => {
  const generations = useStore($generations);
  const { loading, isPanelOpen, setPhase } = usePanelContext();
  const firstGeneration = generations[0];

  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center gap-3 rounded-xl bg-d-surface-bg-tertiary p-2',
        !isPanelOpen && 'bg-transparent p-2'
      )}>
      {firstGeneration && (
        <motion.div
          layout
          className={twMerge(
            'grid w-full justify-center gap-2',
            isPanelOpen ? 'grid-cols-4' : 'grid-cols-1'
          )}>
          {firstGeneration.urls.map((url, index) => (
            <motion.div
              layout
              key={index}
              className='aspect-square cursor-pointer overflow-hidden rounded-lg bg-d-surface-bg-secondary'>
              <ResultsGenerationImage
                imagePath={url}
                isLoading={loading}
                className='h-full w-full object-cover'
              />
            </motion.div>
          ))}
        </motion.div>
      )}
      {isPanelOpen && (
        <SimpleButton
          variant='tertiary'
          onClick={() => setPhase(BeinkPhase.Results)}
          leftIcon={faEye}
          format='rounded'
          size='xs'
          round>
          {t('panel:Results.seeAllGenerations')}
        </SimpleButton>
      )}
    </div>
  );
};

export default LastGeneration;
