import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeleteImageInAlbum from '../../../../hooks/albums/useDeleteImageInAlbum';

type AlbumImageProps = {
  albumId: string;
  imageId: string;
  src: string;
};

const DeletableAlbumImage = ({ src, albumId, imageId }: AlbumImageProps) => {
  const { deleteImageFromAlbum } = useDeleteImageInAlbum();

  return (
    <div className='relative'>
      <div className='aspect-square overflow-hidden rounded-xl'>
        <img src={src} alt='' className='h-full w-full object-cover' />
      </div>
      <div
        className='absolute bottom-full left-full flex -translate-x-2/3 translate-y-2/3 cursor-pointer items-center rounded-full bg-d-surface-bg-base p-1 text-white'
        onClick={() => {
          deleteImageFromAlbum(albumId, imageId);
        }}>
        <FontAwesomeIcon icon={faTrashCan} className='h-[9px] w-[9px]' />
      </div>
    </div>
  );
};

export default DeletableAlbumImage;
